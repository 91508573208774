import Header from './Header'


function NotFound() {
  return (
      <main>
        <Header />
        <h2>Error, Page Not Found!</h2>
      </main>
  );
}

export default NotFound;